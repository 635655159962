<template>
    <div class="migration-page">
      <div class="d-flex align-center pr-3 mt-4">
        <span class="ml-6 text-h6">Data Migration</span>
      </div>
      <div>
        <div class="form-container">
          <!-- <v-autocomplete
              :items="companyList"
              item-text="name"
              item-value="_id"
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="selectedCompany"
              label="Select a Company"
              :clearable="true"
              :loading="companyListLoader"
          ></v-autocomplete> -->
          <v-autocomplete
              :items="environmentList"
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="selectedEnvironment"
              label="Destination environment"
              :clearable="true"
          ></v-autocomplete>
          <v-text-field v-model="remarks" hide-details="auto" label="Remarks"></v-text-field>
          <v-btn rounded :disabled="!selectedEnvironment || loader" class="elevation-0 white--text save-btn btn left-align" height="35px" @click="migrateData()">Migrate Data
            <v-progress-circular v-if="loader"  :size="20" indeterminate color="#0c9577"></v-progress-circular>
          </v-btn>
        </div>
        <v-btn rounded class="right-link elevation-0 white--text save-btn btn negetive-margin" height="35px" @click="$router.push('history')"> View History</v-btn>
      </div>
      <v-snackbar v-model="snackbar" color="#00bf55" height="70px" :timeout="2000" >
        <span class="mdi mdi-check-circle icon-16" color="#fff"></span>
          {{ snackbarText }}
      </v-snackbar>
      <v-snackbar v-model="snackbarWarning" color="#e56565" height="70px" :timeout="2000" >
        <span class="mdi mdi-close-circle icon-16" color="#fff"></span>
          {{ snackbarText }}
      </v-snackbar>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        companyList: [],
        // selectedCompany: '',
        environmentList: [],
        selectedEnvironment: '',
        remarks: '',
        loader: false,
        jobTimer: '',
        snackbarText: '',
        snackbar: false,
        snackbarWarning: false,
        companyListLoader: false
      }
    },
    mounted() {
      this.companyListLoader = true;
      this.$store.dispatch('company/getCompanyList').then((resp) => {
        this.companyList = resp.data.companies;
        this.companyListLoader = false;
      })
      this.$store.dispatch('migration/getEnvs').then((resp) => {
        let data = resp.data.data
        this.environmentList = data.filter((val) => val != 'Admin');
      })
    },
    methods: {
      migrateData() {
        this.loader = true;
        let params = {
            destination_env: this.selectedEnvironment,
            // company_id: this.selectedCompany,
            remarks: this.remarks
        }
        this.$store.dispatch('migration/migrateData', params).then((resp) => {
          console.log('migrateData', resp);
          // const jobId = response.data.job_id;
          this.jobTimer = setInterval(() => { this.trackJob(resp.data.job_id) }, 3000)
        });
      },
      trackJob(id) {
      this.$store.dispatch('migration/trackJob', id)
        .then(response => {
          this.loader = true
          console.log('migrateData', response)
          if (response.data.message == "Completed") {
            this.loader = false
            clearInterval(this.jobTimer)
            this.jobTimer = '';
            this.snackbar = true;
            this.snackbarText = 'Migrated successfully';
            this.resetFields();
            setTimeout(() => {
              this.snackbarText = '';
              this.snackbar = false;
            }, 3000);
          }
          if (response.data.status == "Failure") {
            this.loader = false
            clearInterval(this.jobTimer)
            this.jobTimer = '';
            this.snackbar = true;
            this.snackbarWarning = 'Migration failed';
            this.resetFields();
            setTimeout(() => {
              this.snackbar = false;
              this.snackbarWarning = '';
            }, 3000);
          }
        })
        .catch(error => {
          clearInterval(this.jobTimer)
          this.jobTimer = '';
          this.loader = false;
          this.snackbar = true;
          this.snackbarWarning = error.response.data.message;
          this.resetFields();
          setTimeout(() => {
            this.snackbar = false;
            this.snackbarWarning = '';
          }, 5000);
        })
    },
    resetFields() {
      // this.selectedCompany = '';
      this.selectedEnvironment= '';
      this.remarks = '';
    }
    }
}
</script>
<style lang="scss" scoped>
.form-container {
  max-width: 500px;
  margin-left: 25px;
  margin-top: 25px;
}
.left-align {
  display: block;
  //margin-right: auto;
  margin-left: auto;
  margin-top:20px;
}
.right-link {
  float: right;
  margin-right: 50px;
}
.negetive-margin {
  margin-top: -35px;
}
</style>