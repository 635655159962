import axios from 'axios';

const state = {
}
const mutations = {
}
const actions = {
  getCompanyList({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/company`)
  },
  createv({ commit }, params) {
    return axios.post(`${process.env.VUE_APP_API}/company`, params);
  },
  updateCompany({ commit }, params) {
    return axios.put(`${process.env.VUE_APP_API}/company`, params);
  },
  deleteCompany({ commit }, params) {
    return axios.delete(`${process.env.VUE_APP_API}/company`, {data:params});
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}