<template>
  <v-app>
    <div id="app" class="d-flex">
      <v-row>
        <v-col md="2">
          <div class="nav-bar-draw">
            <nav-bar />
          </div>
        </v-col>
        <v-col md="10">
          <div class="content-draw container-fluid ml-7">
          <div class="icon-container">
            <div class="user-icon">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="profile-icon" v-on="on" v-bind="attrs" :title="userName">{{iconLetters}}</v-btn>
                    <!-- <span v-bind="attrs" height="35px" v-bind="attrs" :attach="true" v-on="on" class="mdi mdi-account-circle" ></span> -->
                </template>
                <v-list>
                  <v-list-item @click="logout()">
                    <v-list-item-title>Logout</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
            <router-view/>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<script>
import Navbar from '@/components/reusable/navbar'
// import EnvDropDown from '@/components/reusable/envDropdown.vue'
import { VRow } from 'vuetify/lib';
import axios from 'axios'

export default {
  components: {
    'nav-bar': Navbar,
    VRow
},
data() {
      return {
      userName: '',
      iconLetters: ''
  }
},
  mounted(){
    // axios.interceptors.request.use(function (config) {
    //     // console.log('route => ', config);

    //     const userString = (this.$cookies.get('auth_jwt'))?this.$cookies.get('auth_jwt'): '';
    //     console.log('userString => ', userString);
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${userString}`
    //     return config
    // }, function(err) {
    //   return Promise.reject(err);
    // }); 
    this.$store.dispatch('auth/getProfileDetail').then((resp) => {
      console.log('response', resp);
      if(resp?.data?.data) {
        this.userName = resp.data.data.fname + resp.data.data.lname
        this.iconLetters = resp.data.data?.fname?.charAt(0) + resp.data.data?.lname?.charAt(0)  
      } else {
        this.userName = 'Super Admin';
        this.iconLetters = 'SA'
      }
    });
  },
  methods: {
        logout() {
            localStorage.removeItem('user');
            this.$cookies.remove("auth_jwt");
            this.$router.push({name: 'Login'})
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import 'assets/styles/main';
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #D62929;
  }
  .nav-bar-draw {
    width: 300px;
  }
  .icon-container {
    height: 55px;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  .user-icon {
      height: 35px;
      float: right;
      margin-right: 10px;
  }
  .user-icon span {
    font-size: 35px;
  }
  .profile-icon{
    height: 35px;
    width: 35px !important;
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    border-radius: 50%;
    padding: 0 !important;
    min-width: auto;
    margin-top: 10px;
    font-size: 14px !important;
  }
  .profile-icon span{
    font-size: 14px !important;
  }
}

</style>
