import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import auth from './auth';
import sections from './sections';
import disciplines from './disciplines';
import items from './items';
import templates from './templates';
import company from './company';
import migration from './migration';

// const NAMESPACE = "65f9af5d-f23f-4065-ac85-da725569fdcd";
const instance = axios.create({
  baseURL: process.env.VUE_APP_API
})

Vue.use(Vuex)

export default new Vuex.Store({
  instance,
   modules: {
    auth,
    sections,
    disciplines,
    items,
    templates,
    company,
    migration
  },
})
