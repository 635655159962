<template>
    <div class="d-flex pr-3 env-drop">
        <v-row>
            <v-col md="9"></v-col>
            <v-col md="3">
                <v-select
                    :items="env"
                    v-model="selectedEnv"
                    @change="setEnv"
                    label="Select Environment"
                    outlined
                    :dense="true"
                    class="mt-3 env-drop-op"
                ></v-select>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data() {
        return {
            env:[],
            selectedEnv: 'Admin'
        }
    },
    mounted() {
        // window.addEventListener('sso-login-success', (event) => {
            // let user = JSON.parse(localStorage.getItem('user'))
            // if (user.jwt) {
                this.getEnv()   
            // }
        // });
    },
    methods: {
        getEnv() {
            axios({
                url: process.env.VUE_APP_API + '/env/info?info_type=env',
                method: 'get'
            }).then((res) => {
                console.log('env', res.data.data);
                this.env = res.data.data
                localStorage.setItem('env', this.selectedEnv)
            })
        },
        setEnv() {
            console.log(this.selectedEnv);
            localStorage.setItem('env', this.selectedEnv)
            window.dispatchEvent(new CustomEvent('env-localstorage-changed', {
                detail: {
                    storage: localStorage.getItem('env')
                }
            }));
        }
    }
}
</script>
<style lang="scss">
    .env-drop-op{
        .v-text-field__details {
            display: none;
        }
        .v-select__selections {
            padding: 0px !important;
            .v-select__selection {
                margin: 0px !important;
            }
        }
        .v-input__slot {
            min-height: 48px !important;
        }
        .v-input__append-inner {
            margin-top: 10px;
        }
    }
</style>
<style lang="scss" scoped>
    .env-drop {
        border-bottom: 1px solid #ddd;
    }
</style>