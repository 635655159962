<template>
    <div class="login-backGroung">
        <v-card width="350px" class="py-8 px-4 text-center login-cart">
            <h3>Login</h3>
            <v-card-text class="d-flex align-center pa-0" v-if="sso">
                <v-progress-circular indeterminate></v-progress-circular>
                <span class="text-caption ml-2">Please wait while we log you into the system!</span>
            </v-card-text>
            <v-card-text class="d-flex flex-column align-center pa-3" v-else>
                <v-text-field
                    label="UserName"
                    v-model="username"
                    outlined
                    style="width: 100%;"
                ></v-text-field>
                <v-text-field
                    label="Password"
                    type="password"
                    outlined
                    style="width: 100%;"
                    v-model="password"
                ></v-text-field>
                <v-btn color="success" class="elevation-0" rounded @click="login()">Login</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            user: {
                token: 'jwt-Ne0mnzP9Pkt=-xPS8pzfzcq-7YExYXPZpRlSE5JLUlTKXX7-AjMYe0g1wlb2C-YurxUVBv3gRJecjIu36N?wOUMKt7WCk9H1bfeVA8s!i3!CbHvDSadMLCkTsrW!EEEn6zSMbzZouH19UMtoRZRd!j!kNyQT6sy6nQZJOQS8wxeB6kLeh5JN3R-cxyyGqJGS1FN4TBtdRdXbXFnVdb??8HVDRYRz7owtcJFYmZnHeGLN0PIpQjOcAvWJHgbh'
            },
            username: '',
            password: '',
            sso: false
        }
    },
    created() {
        document.getElementsByTagName('html')[0].classList.add('overflow-y-hidden');
        localStorage.setItem('env', 'Admin')
        this.ssoCheck()
    },
    methods: {
        ssoCheck() {
            axios({
                url: process.env.VUE_APP_API + '/ssocheck',
                method: 'get'
            }).then((res) => {
                // console.log(res.data);
                if (res.data.is_sso_enabled) {
                    this.ssoLogin()
                }
            })
        },
         ssoLogin() {
            let userJwt = (this.$cookies.get('auth_jwt'))? this.$cookies.get('auth_jwt') : ''
            let fallbackUrl = ''
            let callTell = (userJwt)? 'sso2': 'sso'
            if (userJwt) {
                fallbackUrl = window.location.origin + '/'
            }
            let urlString = process.env.VUE_APP_API + '/ssologin?' + 'sso2' +'&auth_token=' + userJwt + '&return_url=' + fallbackUrl
            console.log(process.env.VUE_APP_API + '/ssologin?' + 'sso2' +'&auth_token=' + userJwt + '&return_url=' + fallbackUrl);
            axios({
                url: urlString,
                method: 'get'
            }).then((res) => {
                window.dispatchEvent(new CustomEvent('sso-login-success'));
                // console.log('user data => ', res.data);
                localStorage.setItem('user', JSON.stringify(res.data))
                this.$router.push({name: 'home'})
             }).catch((err) => {
                if (err.response.status === 302) {
                    window.location.replace(err.response.data.redirect_url)
                }
            })
         },
         login() {
            // console.log('state', this.$store.state, this.username, this.password,this.$store.state.auth.Username,this.$store.state.auth.Password, (this.username == this.$store.state.auth.Username && this.password == this.$store.state.auth.Password));
            if (this.username == this.$store.state.auth.Username && this.password == this.$store.state.auth.Password) {
                console.log('login');
                localStorage.setItem('user', JSON.stringify(this.user))
                this.$router.push({name: 'home'})
            }
         }
    }
}
</script>
<style lang="scss" scoped>
    .login-cart {
        position: fixed;
        right: 10px;
        top: 30%;
    }
    .login-backGroung {
        background-color: #fff;
        background-image: url('@/assets/5040007.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        overflow-y: hidden;
        // width: 100%;
        // height: 99.6vh;
    }
</style>