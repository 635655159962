<template>
    <div class="login-backGroung">
        <v-card width="350px" class="py-8 px-4 text-right login-cart">
            <h3>Login</h3>
            <v-card-text class="d-flex align-center pa-0">
                <h6 class="red--text font-weight-bold mt-5" style="line-height:24px">You are not authorized to use<br> Rudy for Engineers.</h6>
                <h6 class="dark--text font-weight-bold mt-2 mb-5" style="line-height:24px">Please contact your administrator.</h6>
            </v-card-text>
        </v-card>
    </div>
</template>
  <script>
  import axios from 'axios'
  
  export default {
    data() {
      return {
        email: '',
        password: '',
        error: null,
        forgotemail: '',
        resetlink: null,
        forgotError: null,
        serverError: null,
        submitted: false,
        SSOEnabled:false,
        ssoLoggingInLoader:false,
        prevRoute:null,
        getCookie:'',
        returnUrl:''
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from
        console.log('vm.prevRoute',vm.prevRoute);
      })
    },
    created () {
    },
    computed: {
    },
    methods: {
    },
    mounted (){
      axios.get(`${process.env.VUE_APP_API_AUTH}/ssocheck`)
      .then(response => {
        if (!response.data.is_sso_enabled) {
            this.$router.push({ path: '/login' })
        }
      })
      .catch(err => {
        console.log('error', err);
      })
    },
    beforeCreate: function() {
  
    },
  };
  </script>
<style lang="scss" scoped>
.login-cart {
    position: fixed;
    right: 10px;
    top: 40%;
}
.login-backGroung {
    background-image: url('@/assets/5040007.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    overflow-y: hidden;
    // width: 100%;
    // height: 99.6vh;
}
</style>
  