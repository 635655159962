<template>
    <div class="itemsListing">
        <div class="d-flex align-center pr-3 mt-4">
            <span class="ml-6 text-h6">Sections</span>
            <v-spacer></v-spacer>
            <v-text-field label="Search items" v-model="searchItem" style="max-width: 450px;"></v-text-field>
          </div>
          <v-btn rounded class="elevation-0 white--text save-btn btn right-btn" height="35px" @click="editItem= {}; createSection = true">Add Section</v-btn>  
        <v-data-table no-data-text="No record found" :items-per-page="10" :headers="headers" :items="sectionListing" class="elevation-1 pa-2" :loading="loadData"
            :search="searchItem">
            <template v-slot:item.is_active="{ item }">
                <span>{{item.is_active ? 'Active': 'Inactive'}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <span class="mdi mdi-pencil" @click="editSection(item)" title="Edit"></span>
                <span class="mdi mdi-delete" :disabled="true" color="red" title="Delete" style="cursor: pointer;"
                @click.stop="deletePopup = true; deleteItem = item"></span>
                <v-btn small rounded class="elevation-0 btn basic-button" :class="item.is_active ? 'cancel-btn' : 'save-btn'" @click="disableSection(item)">{{item.is_active ? 'Disable' : 'Enable'}}</v-btn>
                <!-- <span class="mdi mdi-publish-off" @click="disableSection(item)" title="Disable"></span> -->
            </template>
        </v-data-table>
        <v-dialog v-model="deletePopup" persistent max-width="500px" transition="dialog-transition">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    <span>Delete Section</span>
                    <v-icon style="cursor: pointer;" @click="deletePopup = false;" title="close">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="align-left">
                    Are you sure you want to delete this section?
                </v-card-text>
                <v-card-actions class="btm-padding">
                    <v-spacer></v-spacer>
                    <v-btn color="success" small rounded class="elevation-0 save-btn btn" @click.stop="deleteSection(deleteItem)">Delete</v-btn>
                    <v-btn color="red" small rounded class="white--text elevation-0 cancel-btn btn"
                        @click="deletePopup = false;">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createSection" class="" width="500px" persistent>
            <v-card width="500px" class="pa-5">
                <div class="d-flex justify-space-between">
                    <h4>{{editItem && editItem._id ? 'Edit section' : 'Add section'}}</h4>
                    <span @click="createSection = false" style="cursor: pointer;" title="close"><v-icon>mdi-close</v-icon></span>
                </div>
                <div class="">
                     <!-- <v-input v-model="sectionName" type="text" placeholder="Section name"/> -->
                     <v-text-field v-model="sectionName" hide-details="auto" label="Section name"></v-text-field>
                     <!-- <div class="radio-label">Line Item Definition</div> -->
                     <!-- <v-radio-group v-model="isRequired" class="process-radio" row>
                        <v-radio label="Yes" value="yes"></v-radio>
                        <v-radio label="No" value="no"></v-radio>
                    </v-radio-group> -->
                </div>
                <v-card-actions class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn rounded class="elevation-0 white--text save-btn btn" height="35px" :disabled="!sectionName" @click="createNewSection()">{{this.editItem._id ? 'Update' :'Create'}}</v-btn>
                    <v-btn rounded class="elevation-0 white--text cancel-btn btn" height="35px" @click="createSection = false; resetModal()">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="#00bf55" height="70px" :timeout="2000" >
            <span class="mdi mdi-check-circle icon-16" color="#fff"></span>
            {{ snackbarText }}
        </v-snackbar>
        <v-snackbar v-model="snackbarWarning" color="#e56565" height="70px" :timeout="2000" >
            <span class="mdi mdi-close-circle icon-16" color="#fff"></span>
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        headers: [
        { text: 'Item Type', value:'name', width: '250px'},  
        // { text: 'Line Item Definition', value: 'line_item', width: '100px' },
        { text: 'Status', value:'is_active', width: '85px'},
        { text: 'Last Updated', value:'updated_at' , width: '200px'},
        { text: 'Creator', value:'created_by', width: '150px'},
        { text: 'Actions', value:'actions', align: 'center', width: '200px', sortable: false},
        ],
        searchItem: '',
        deleteItem: {},
        createSection: false,
        deletePopup: false,
        sectionListing: [],
        loadData: false,
        sectionName: '',
        isRequired: '',
        editItem: {},
        snackbarText: '',
        snackbar: false,
        snackbarWarning: false
      }
    },
    components: {
    },
    mounted() {
        this.getSectionList();
    },
    created() {
      this.envChange = localStorage.getItem('env')
    },
    methods: {
        getSectionList() {
            this.loadData = true;
            this.$store.dispatch('sections/getSectionList').then((resp) => {
                this.sectionListing = resp.data.sections;
                this.loadData = false;
            })
        },
        editSection(item) {
            this.createSection = true; 
            this.editItem = item;
            this.sectionName = item.name
            // this.isRequired = item.line_item === 'Not Required' ? 'no' : 'yes'
        },
        createNewSection() {
            let params = {
                section_name: this.sectionName,
                // line_item: this.isRequired
            }
            if(this.editItem._id) {
                params['section_id'] = this.editItem._id;
                this.$store.dispatch('sections/updateSection', params).then(() => {
                    this.getSectionList();
                    this.resetModal();
                    this.snackbarText = 'Updated Successfully';
                    this.snackbar = true;
                }).catch((err) => {
                    this.snackbarText = err.response.data.message;
                    this.snackbarWarning = true;
                });
            } else {
                this.$store.dispatch('sections/creteSection', params).then(() => {
                    this.getSectionList();
                    this.resetModal();
                    this.snackbarText = 'Created Successfully';
                    this.snackbar = true;
                }).catch((err) => {
                    this.snackbarText = err.response.data.message;
                    this.snackbarWarning = true;
                });
            }
        },
        disableSection(item) {
            let params = {
                section_id: item._id,
                is_active: !item.is_active,
                section_name: item.name
            }
            this.$store.dispatch('sections/updateSection', params).then(() => {
                this.getSectionList();
                this.snackbarText = 'Updated Successfully';
                this.snackbar = true;
            }).catch((err) => {
                this.snackbarText = err.response.data.message;
                this.snackbarWarning = true;
            });
        },
        deleteSection(item) {
            let params = {
                section_id: item._id,
                is_delete: true
            }
            this.$store.dispatch('sections/deleteSection', params).then(() => {
                this.getSectionList();
                this.deleteItem= {};
                this.deletePopup = false;
                this.snackbarText = 'Deleted Successfully';
                this.snackbar = true;
            }).catch((err) => {
                this.snackbarText = err.response.data.message;
                this.snackbarWarning = true;
            });
        },
        resetModal() {
            this.createSection = false;
            this.sectionName = '';
            this.line_item = '';
        }
    }
  }
</script>
<style lang="scss" scoped>
.text-center {
    .mdi {
        font-size: 20px;
        padding: 0 5px;
        cursor: pointer;
    }
}
.radio-label {
    text-align: left;
    margin-top: 25px;
    margin-bottom: -10px;
}
.right-btn {
    margin-right: 10px;
    margin-left: auto;
    display: block;
    margin-bottom: 5px;
}
.align-left{
    text-align: left;
}
.btm-padding{
    padding-bottom: 15px;
}
.modal-btn {
    margin-bottom: 10px;
}
.basic-button {
    border: 1px solid #7f7b7b;
}
</style>
  