<template>
    <div class="itemsListing">
        <div class="d-flex align-center pr-3 mt-4">
            <span class="ml-6 text-h6">Associated Items</span>
            <v-spacer></v-spacer>
            <v-text-field label="Search items" v-model="searchItem" style="max-width: 450px;"></v-text-field>
          </div>
          <v-btn rounded class="elevation-0 white--text save-btn btn right-btn" height="35px" @click="editItem= {}; createItem = true">Associate Item</v-btn>  
          <v-data-table :headers="headers" no-data-text="No record found" :items="associateList" :items-per-page="10" class="pa-2" :loading="loadData"
            :search="searchItem">
            <template v-slot:item.is_active="{ item }">
                <span>{{item.is_active ? 'Active': 'Inactive'}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <span class="mdi mdi-pencil" @click="editItems(item)" title="Edit"></span>
                <span class="mdi mdi-delete" :disabled="true" color="red" title="Delete" style="cursor: pointer;"
                @click.stop="deletePopup = true; deleteItem = item"></span>
            </template>
        </v-data-table>
        <v-dialog v-model="deletePopup" persistent max-width="500px" transition="dialog-transition">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    <span>Delete Item</span>
                    <v-icon style="cursor: pointer;" @click="deletePopup = false;" title="close">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="align-left">
                    Are you sure you want to delete this Item?
                </v-card-text>
                <v-card-actions class="btm-padding">
                    <v-spacer></v-spacer>
                    <v-btn color="success" small rounded class="elevation-0 save-btn btn" @click.stop="deleteItems(deleteItem)">Delete</v-btn>
                    <v-btn color="red" small rounded class="white--text elevation-0 cancel-btn btn"
                        @click="deletePopup = false;">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createItem" content-class="associateItem" width="500px" overflow="visible" persistent>
            <v-card width="500px" class="pa-5">
                <div class="d-flex justify-space-between">
                    <h4>{{editItem && editItem._id ? 'Edit Item' : 'Add Item'}}</h4>
                    <span @click="resetModal()" style="cursor: pointer;" title="close"><v-icon>mdi-close</v-icon></span>
                </div>
                <div class="">
                    <!-- <v-autocomplete
                        :items="companyList"
                        item-text="name"
                        item-value="_id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="selectedCompany"
                        label="Select a Company"
                        :clearable="true"
                    ></v-autocomplete> -->
                    <v-autocomplete
                        :items="itemList"
                        item-text="name"
                        item-value="_id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="selectedItem"
                        label="Select an item"
                        :clearable="true"
                        :attach="true"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="templateList"
                        item-text="name"
                        item-value="_id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="selectedTemplate"
                        label="Select a Template"
                        :clearable="true"
                        :attach="true"
                    ></v-autocomplete>
                    <!-- <label>Data Dictionary</label>
                    <v-file-input style="margin-left: -6px;" accept=".xlsx" ref="fileData" label="Drop or Click the zone to add a template file" v-model="files"></v-file-input> -->
                </div>
                <v-card-actions class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn rounded class="elevation-0 white--text save-btn btn" height="35px" @click="createNewItem()">{{editItem && editItem._id ? 'Update' : 'Create'}}</v-btn>
                    <v-btn rounded class="elevation-0 white--text cancel-btn btn" height="35px" @click="createItem=false; resetModal();">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="#00bf55" height="70px" :timeout="2000" >
            <span class="mdi mdi-check-circle icon-16" color="#fff"></span>
            {{ snackbarText }}
        </v-snackbar>
        <v-snackbar v-model="snackbarWarning" color="#e56565" height="70px" :timeout="2000" >
            <span class="mdi mdi-close-circle icon-16" color="#fff"></span>
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        headers: [
        { text: 'Company', value:'company', width: '200px'},  
        { text: 'Item', value: 'item', width: '100px' },
        { text: 'Template', value: 'template', width: '100px' },
        { text: 'Status', value:'is_active', width: '85px'},
        { text: 'Last updated ', value:'updated_at' , width: '150px'},
        { text: 'Updated by', value:'updated_by', width: '120px'},
        { text: 'Actions', value:'actions', align: 'center', width: '200px', sortable: false},
        ],
        searchItem: '',
        deleteItem: {},
        createItem: false,
        deletePopup: false,
        associateList: [],
        companyList: [],
        itemList: [],
        templateList: [],
        //selectedCompany: '',
        selectedItem: '',
        selectedTemplate: '',
        itemName: '',
        loadData: false,
        snackbarText: '',
        snackbar: false,
        snackbarWarning: false,
        editItem: {}
      }
    },
    components: {
    },
    mounted() {
        this.getAssociateItemList();
        this.$store.dispatch('company/getCompanyList').then((resp) => {
            this.companyList = resp.data.companies;
        });
        this.$store.dispatch('items/getItemsList').then((resp) => {
            this.itemList = resp.data.items;
        });
        this.$store.dispatch('templates/getTemplateList').then((resp) => {
            this.templateList = resp.data.templates.filter((item) => item.is_active);
        })
    },
    created() {
      this.envChange = localStorage.getItem('env')
    },
    methods: {
        getAssociateItemList() {
            this.associateList = [];
            this.loadData = true;
            this.$store.dispatch('items/getAssociateItemList').then((resp) => {
                this.associateList = resp.data.data;
                this.loadData = false;
            })
        },
        editItems(item) {
            this.createItem = true; 
            this.editItem = item;
            // this.selectedCompany = item.company_id;
            this.selectedItem = item.item_id;
            this.selectedTemplate = item.template_id;
        },
        createNewItem() {
            this.loadData = true;
            let params = {
                item_id: this.selectedItem,
                // company_id: this.selectedCompany,
                template_id: this.selectedTemplate
                }
            if(this.editItem._id) {
                params['item_associate_id'] = this.editItem._id;
                this.$store.dispatch('items/updateAssociatItem', params).then(() => {
                    this.getAssociateItemList();
                    this.resetModal();
                    this.snackbarText = 'Updated Successfully';
                    this.snackbar = true;
                }).catch((err) => {
                    this.snackbarText = err.response.data.message;
                    this.snackbarWarning = true;
                });
            } else {
                this.$store.dispatch('items/associateItem', params).then(() => {
                    this.getAssociateItemList();
                    this.resetModal();
                    this.snackbarText = 'Created Successfully';
                    this.snackbar = true;
                }).catch((err) => {
                    this.snackbarText = err.response.data.message;
                    this.snackbarWarning = true;
                });
            }
        },
        deleteItems(item) {
            let params = {
                item_associate_id: item._id,
                is_delete: true
            }
            this.$store.dispatch('items/deleteAssociateItem', params).then(() => {
                this.getAssociateItemList();
                this.deleteItem= {};
                this.deletePopup = false;
                this.snackbarText = 'Deleted Successfully';
                this.snackbar = true;
            }).catch((err) => {
                this.snackbarText = err.response.data.message;
                this.snackbarWarning = true;
                this.deletePopup = false;
            });
        },
        resetModal() {
            this.createItem = false;
            // this.selectedCompany = '';
            this.selectedItem = '';
            this.selectedTemplate = '';
        }
    }
  }
</script>
<style lang="scss" scoped>
.text-center {
    .mdi {
        font-size: 20px;
        padding: 0 5px;
        cursor: pointer;
    }
}
.radio-label {
    text-align: left;
    margin-top: 25px;
    margin-bottom: -10px;
}
.right-btn {
    margin-right: 10px;
    margin-left: auto;
    display: block;
    margin-bottom: 5px;
}
.align-left{
    text-align: left;
}
.btm-padding{
    padding-bottom: 15px !important;
}
.modal-btn {
    margin-bottom: 10px;
}
.basic-button {
    border: 1px solid #7f7b7b;
}

</style>