import axios from 'axios';

const state = {
}
const mutations = {
}
const actions = {
  getTemplateList({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/template`)
  },
  createTemplate({ commit }, params) {
    return axios.post(`${process.env.VUE_APP_API}/template`, params);
  },
  updateTemplate({ commit }, params) {
    return axios.put(`${process.env.VUE_APP_API}/template`, params);
  },
  disableTemplate({ commit }, params) {
    return axios.delete(`${process.env.VUE_APP_API}/template`, {data:params});
  },
  deleteTemplate({ commit }, params) {
    return axios.delete(`${process.env.VUE_APP_API}/template`, {data:params});
  },
  publishTemplate({ commit }, params) {
    return axios.post(`${process.env.VUE_APP_API}/template/publish`, params);
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}