<template>
    <div class="itemsListing">
        <div class="d-flex align-center pr-3 mt-4">
            <span class="ml-6 text-h6">Disciplines</span>
            <v-spacer></v-spacer>
            <v-text-field label="Search items" v-model="searchItem" style="max-width: 450px;"></v-text-field>
          </div>
          <v-btn rounded class="elevation-0 white--text save-btn btn right-btn" height="35px" @click="editItem= {}; createItem = true">Add Discipline</v-btn>  
        <v-data-table no-data-text="No record found" :headers="headers" :items="disciplineList" :items-per-page="10" class="pa-2" :loading="loadData"
            :search="searchItem">
            <template v-slot:item.is_bulk="{ item }">
                <span>{{item.is_bulk ? 'Yes': 'No'}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <span class="mdi mdi-pencil" @click="editDiscipline(item)" title="Edit"></span>
                <span class="mdi mdi-delete" :disabled="true" color="red" title="Delete" style="cursor: pointer;"
                @click.stop="deletePopup = true; deleteItem = item"></span>
            </template>
        </v-data-table>
        <v-dialog v-model="deletePopup" persistent max-width="500px" transition="dialog-transition">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    <span>Delete Discipline</span>
                    <v-icon style="cursor: pointer;" @click="deletePopup = false;" title="close">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="align-left">
                    Are you sure you want to delete this Discipline?
                </v-card-text>
                <v-card-actions class="btm-padding">
                    <v-spacer></v-spacer>
                    <v-btn color="success" small rounded class="elevation-0 save-btn btn" @click.stop="deleteDiscipline(deleteItem)">Delete</v-btn>
                    <v-btn color="red" small rounded class="white--text elevation-0 cancel-btn btn"
                        @click="deletePopup = false;">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createItem" class="" width="500px" persistent>
            <v-card width="500px" class="pa-5">
                <div class="d-flex justify-space-between">
                    <span>Create Discipline</span>
                    <span @click="createItem = false" style="cursor: pointer;" title="close"><v-icon>mdi-close</v-icon></span>
                </div>
                <div class="">
                    <v-text-field v-model="disciplineName" hide-details="auto" label="Discipline name"></v-text-field>
                    <v-text-field v-model="description" hide-details="auto" label="Description"></v-text-field>
                </div>
                <v-card-actions class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn rounded class="elevation-0 white--text save-btn btn" height="35px" @click="createNewDiscipline()">{{editItem && editItem._id ? 'Update' : 'Create'}}</v-btn>
                    <v-btn rounded class="elevation-0 white--text cancel-btn btn" height="35px" @click="createItem=false; resetModal();">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="#00bf55" height="70px" :timeout="2000" >
            <span class="mdi mdi-check-circle icon-16" color="#fff"></span>
            {{ snackbarText }}
        </v-snackbar>
        <v-snackbar v-model="snackbarWarning" color="#e56565" height="70px" :timeout="2000" >
            <span class="mdi mdi-close-circle icon-16" color="#fff"></span>
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>
  <script>
  export default {
    name: 'Disciplines-list',
    data() {
      return {
        headers: [
        { text: 'Name', value:'name', width: '200px'},  
        { text: 'Description', value: 'description', width: '100px' },
        // { text: 'Bulk purchase', value:'is_bulk', width: '120px'},
        { text: 'Last updated', value:'updated_at' , width: '150px'},
        { text: 'Updated by', value:'updated_by', width: '150px'},
        { text: 'Actions', value:'actions', align: 'center', width: '150px', sortable: false},
        ],
        searchItem: '',
        loadData: false,
        deleteItem: {},
        createItem: false,
        deletePopup: false,
        disciplineList: [],
        disciplineName: '',
        description: '',
        snackbarText: '',
        snackbar: false,
        snackbarWarning: false,
        editItem: {}
      }
    },
    components: {
    },
    mounted() {
        this.getDisciplineList();
    },
    created() {
      this.envChange = localStorage.getItem('env')
    },
    methods: {
        getDisciplineList() {
            this.loadData = true;
            this.$store.dispatch('disciplines/getDisciplineList').then((resp) => {
                this.disciplineList = resp.data.disciplines;
                this.loadData = false;
            })
        },
        editDiscipline(item) {
            this.createItem = true; 
            this.editItem = item;
            this.disciplineName = item.name;
            this.description = item.description;
            // this.isRequired = item.line_item === 'Not Required' ? 'no' : 'yes'
        },
        createNewDiscipline () {
            let params = {
                discipline_name: this.disciplineName,
                description: this.description,
            }
            if(this.editItem._id) {
                params['discipline_id'] = this.editItem._id;
                this.$store.dispatch('disciplines/updateDiscipline', params).then(() => {
                    this.getDisciplineList();
                    this.resetModal();
                    this.snackbarText = 'Updated Successfully';
                    this.snackbar = true;
                }).catch((err) => {
                    this.snackbarText = err.response.data.message;
                    this.snackbarWarning = true;
                    this.resetModal();
                });
            } else {
                this.$store.dispatch('disciplines/createDiscipline', params).then(() => {
                    this.getDisciplineList();
                    this.resetModal();
                    this.snackbarText = 'Created Successfully';
                    this.snackbar = true;
                }).catch((err) => {
                    this.snackbarText = err.response.data.message;
                    this.snackbarWarning = true;
                    this.resetModal();
                });
            }
        },
        deleteDiscipline(item) {
            let params = {
                discipline_id: item._id,
                is_delete: true
            }
            this.$store.dispatch('disciplines/deleteDiscipline', params).then(() => {
                this.getDisciplineList();
                this.deleteItem= {};
                this.deletePopup = false;
                this.snackbarText = 'Deleted Successfully';
                this.snackbar = true;
            }).catch((err) => {
                this.snackbarText = err.response.data.message;
                this.snackbarWarning = true;
                this.deletePopup = false;
            });
        },
        resetModal() {
            this.createItem = false;
            this.disciplineName = '';
            this.description = '';
        }
    }
  }
</script>
<style lang="scss" scoped>
.text-center {
    .mdi {
        font-size: 20px;
        padding: 0 5px;
        cursor: pointer;
    }
}
.radio-label {
    text-align: left;
    margin-top: 25px;
    margin-bottom: -10px;
}
.right-btn {
    margin-right: 10px;
    margin-left: auto;
    display: block;
    margin-bottom: 5px;
}
.align-left{
    text-align: left;
}
.btm-padding{
    padding-bottom: 15px !important;
}
.modal-btn {
    margin-bottom: 10px;
}
.basic-button {
    border: 1px solid #7f7b7b;
}
</style>