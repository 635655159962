<template>
    <div class="usage-reports container pa-12">
       <h2>Usage Reposts</h2>
       <div class="d-flex align-center mt-2">
            <v-autocomplete v-model="selectedEnv" :items="envList" label="Select Environment" outlined dense @change="getTenet(selectedEnv)"></v-autocomplete> 
            <v-autocomplete v-model="selectedTenet" :items="tenetList" item-text="name" item-value="_id" label="Select Tenant" outlined dense class="ml-2" :disabled="selectedEnv == ''"></v-autocomplete>
            <v-menu ref="startmenu" v-model="startdateMenu" :close-on-content-click="false" :return-value.sync="startDate" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="startDate" label="Start Date" outlined dense readonly v-bind="attrs" v-on="on" :disabled="selectedTenet == ''"  class="ml-2"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" :max="maxDate" no-title scrollable>
                    <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startdateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.startmenu.save(startDate)">OK</v-btn>
                </v-date-picker>
            </v-menu>
            <v-menu ref="endmenu" v-model="enddateMenu" :close-on-content-click="false" :return-value.sync="endDate" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="endDate" label="End Date" outlined dense readonly v-bind="attrs" v-on="on" :disabled="startDate == ''"  class="ml-2"></v-text-field>
                </template>
                <v-date-picker v-model="endDate" :min="startDate" :max="maxDate" no-title scrollable>
                    <v-spacer></v-spacer>
                <v-btn text color="primary" @click="enddateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.endmenu.save(endDate)">OK</v-btn>
                </v-date-picker>
            </v-menu>
       </div>
       <div class="d-flex justify-center mt-2 mb-2">
            <v-alert type="info" outlined dense border-left>
                Please fill all the parameters given above to View or Download the reports.
            </v-alert>
       </div>
       <div class="text-center mt-2">
            <v-btn color="success" elevation="0" rounded @click="getRepost" :disabled="selectedEnv == '' || selectedTenet == '' || startDate == '' || endDate == ''">Generate Report</v-btn>
            <v-btn color="error" text elevation="0" rounded class="ml-2" v-if="selectedEnv != '' || selectedTenet != '' || startDate != '' || endDate != ''" @click="resetInput">Reset</v-btn>
       </div>
       <div class="report-view mt-12" v-if="showReport">
            <div class="d-flex align-center justify-end">
                <b>Total Bid Tab Count:</b> <v-chip color="success" class="ml-1" small>{{ total_count }}</v-chip>
                <v-btn color="info" class="ml-6" elevation="0" small rounded @click="exportRepost" :disabled="selectedEnv == '' || selectedTenet == '' || startDate == '' || endDate == ''">Download Report</v-btn>
            </div>
            <div>
                <!-- {{ report }} -->
                  <v-data-table
                    :headers="headers"
                    :items="report"
                    class="elevation-1 mt-4"
                  >
                    <template v-slot:item.item_type="{item}">
                        <table style="width: 100%;">
                            <tbody>
                                <tr  v-for="(itm, i) in item.item_type" :key="i" class="item-row">
                                    <td>
                                        {{ itm.name }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-slot:item.item_cat="{item}">
                        <table style="width: 100%;">
                            <tbody>
                                <tr  v-for="(itm, i) in item.item_cat" :key="i" class="item-row">
                                   
                                    <td>
                                        {{ itm.val }}
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-slot:item.item_cnt="{item}">
                        <table style="width: 100%;">
                            <tbody>
                                <tr  v-for="(itm, i) in item.item_cnt" :key="i" class="item-row">
                                    
                                    <td>
                                        {{itm.type}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-slot:no-data>
                        No report available for selected dates.
                    </template>
                  </v-data-table>
            </div>
       </div>

       <div class="loader" v-if="loader">
        <v-progress-circular
        indeterminate
        color="green"
        ></v-progress-circular>
        Generating Report please wait...
       </div>
    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'

export default {
    data: () => ({
        headers: [
        { text: 'Project', value: 'project_name' },
        { text: 'Item Name', value: 'item_type', align:'left', sortable: false },
        { text: 'Category', value: 'item_cnt', align:'left', sortable: false },
        { text: 'Bid Tab Count', value: 'item_cat', align:'center', sortable: false },
        { text: 'Total Bid Tab Count', value: 'mr_count', align:'center' },
        ],
        envList: [],
        selectedEnv: '',
        selectedTenet: '',
        tenetList: [],
        startdateMenu: false,
        startDate: '',
        enddateMenu: false,
        endDate: '',
        maxDate: '',
        jobId: '',
        jobTimer: '',
        loader: false,
        report: [],
        total_count: 0,
        showReport: false
    }),
    created() {
        this.maxDate = moment(new Date()).format('YYYY-MM-DD')
    },
    mounted() {
        this.getEnv()
    },
    methods: {
        resetInput() {
            this.selectedEnv = ''
            this.selectedTenet = ''
            this.startDate = ''
            this.endDate = ''
            this.startdateMenu = false
            this.enddateMenu = false
            this.loader = false
            this.$refs.startmenu.save(this.startDate)
            this.$refs.endmenu.save(this.endDate)
            this.showReport = false
            clearInterval(this.jobTimer)
                this.jobTimer = ''
        },
        getEnv() {
            axios({
                url: process.env.VUE_APP_API + '/env/info?info_type=env',
                method: 'get'
            }).then((res) => {
                console.log(res);
                this.envList = res.data.data
                this.envList = this.envList.filter(item => !(item == 'Admin'))
            })
        },
        getTenet(val) {
            axios({
                url: process.env.VUE_APP_API + '/env/tenants?target_env=' + val,
                method: 'get'
            }).then((res) => {
                console.log(res);
                this.tenetList = res.data.companies
            })
        },
        getRepost() {
            let payload = {
                from_date_range: this.startDate,
                to_date_range: this.endDate,
                task_type: 'view',
                target_env: this.selectedEnv,
                target_company_id: this.selectedTenet
            }
            console.log(payload);
            this.loader = true
            axios({
                url: process.env.VUE_APP_API + '/consumptionreport',
                method: 'post',
                data: payload
            }).then((res) => {
                console.log(res);
                this.showReport = true
                if (res.data.job_id) {
                    this.jobId = res.data.job_id
                    this.jobTimer = setInterval(this.pollJobView, 1000);   
                } else {
                    this.report = []
                    this.total_count = 0
                    this.loader = false
                }
            }).catch((err) => {
                alert('error => ', err);
                clearInterval(this.jobTimer)
                this.jobTimer = ''
                this.showReport = false
            })
        },
        exportRepost() {
            let payload = {
                from_date_range: this.startDate,
                to_date_range: this.endDate,
                task_type: 'export',
                target_env: this.selectedEnv,
                target_company_id: this.selectedTenet
            }
            console.log(payload);
            this.loader = true
            axios({
                url: process.env.VUE_APP_API + '/consumptionreport',
                method: 'post',
                data: payload
            }).then((res) => {
                console.log(res);
                this.jobId = res.data.job_id
                this.jobTimer = setInterval(this.pollJobEport, 1000);
            }).catch((err) => {
                alert('error => ', err);
                clearInterval(this.jobTimer)
                this.jobTimer = ''
            })
        },
        pollJobEport() {
            axios({
                url: process.env.VUE_APP_API + '/consumptionreport/job?job_id=' + this.jobId + '&job_type=export',
                method: 'get'
            }).then((res) => {
                console.log('export => ',res.data);
                if (res.data.progress == 100) {
                    this.loader = false
                    window.open(res.data.report_s3_file_url, '_blank')  
                    clearInterval(this.jobTimer)
                    this.jobTimer = '' 
                }
            }).catch((err) => {
                alert('error => ', err);
                clearInterval(this.jobTimer)
                this.jobTimer = ''
            })
        },
        pollJobView() {
            axios({
                url: process.env.VUE_APP_API + '/consumptionreport/job?job_id=' + this.jobId + '&job_type=view',
                method: 'get'
            }).then((res) => {
                if (res.data.status == 'success') {
                    clearInterval(this.jobTimer)
                    this.showReport = true
                    this.loader = false
                    this.jobTimer = ''
                    this.report = res.data.data[2].report
                    this.total_count = res.data.data[1].total_mr_count
                    res.data.data[2].report.forEach((itm, i) => {
                        let key1 = Object.keys(itm.items)
                        let item1 = []
                        let item2 = []
                        let item3 = []
                        key1.forEach((ky) => {
                            item1.push({name: ky})
                            item2.push({val: itm.items[ky]})
                            item3.push({name: ky, type: ''})
                        })
                        let key2 = Object.keys(itm.item_categories)
                        // console.log('item => ', item1);
                        key2.forEach((ky) => {
                            item3.forEach((km) => {
                                if (ky == km.name) {
                                    km.type = itm.item_categories[ky]   
                                }
                            })
                        })
                        this.report[i].item_type = item1
                        this.report[i].item_cat = item2
                        this.report[i].item_cnt = item3
                    })
                    console.log('report => ', this.report);
                }
            }).catch((err) => {
                alert('error => ', err);
                this.loader = false
                clearInterval(this.jobTimer)
                this.jobTimer = ''
                this.showReport = false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .usage-reports {
        text-align: left;
        
    }
    .loader {
        position: fixed;
        bottom: 24px;
        right: 24px;
        background-color: #fff;
        z-index: 10;
        box-shadow: 5px 3px 30px 6px #ddd;
        padding: 24px;
        border-radius: 16px;
    }
    .report-table {
        width: 100%;
    }
    .item-row:not(:last-child) {
        border-bottom: 1px solid #ddd;

    }
</style>