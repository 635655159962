import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import Notification from '@/components/reusable/notificationSnack.vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'

Vue.component('noti-snac', Notification)
Vue.config.productionTip = false
Vue.config.performance = true
Vue.prototype.$log = console.log.bind(console)
Vue.use(VueCookies, { expires: '1d'})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    const userString = (this.$cookies.get('auth_jwt'))?this.$cookies.get('auth_jwt'): '';
    console.log('userString => ', userString);
    axios.defaults.headers.common['Authorization'] = `Bearer ${userString}`
  },
}).$mount('#app')
