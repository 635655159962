<template>
    <div>
        <v-card  width="256" class="text-left nav-bar" dark>
            <v-navigation-drawer permanent class="nav-draw">
                <v-list-item>
                    <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Admin Portal
                    </v-list-item-title>
                    <v-list-item-subtitle>
                       {{ userDetails.user.fname }} {{ userDetails.user.lname }}
                    </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense nav>
                    <v-list-item link to="/"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-database-arrow-up"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Prompt Template</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/disciplines"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-pin"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Disciplines</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/items"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-menu"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Items</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item link to="/rules"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-calculator"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Rules</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item link to="/sections"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-family-tree"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Sections</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/templates"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-code-tags"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Templates</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/associteItem"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-paperclip"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Associate Item</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/migration"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-database-export"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Data Migration</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/usageReport"  active-class="href-active">
                        <v-list-item-icon class="mx-2">
                            <span class="mdi mdi-chart-bar"></span>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Usage Report</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <!-- <v-btn class="logout-btn" color="dark" text rounded @click="logout">Logout</v-btn> -->
        </v-card>
    </div>
</template>

<script>

export default {
    data() {
        return {
            userDetails: {
                user: {
                    fname: '',
                    lname: ''
                }
            }
        }
    },
    mounted() {
        window.addEventListener('sso-login-success', (event) => {
            this.userDetails = JSON.parse(localStorage.getItem('user'))
        });
    },
    // methods: {
    //     logout() {
    //         localStorage.removeItem('user');
    //         this.$cookies.remove("auth_jwt");
    //         this.$router.push({name: 'Login'})
    //     }
    // }
}
</script>

<style lang="scss" scoped>
.nav-bar {
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    border-radius: 0px 16px 16px 0px !important;
}
.href-active {
    background-color: #363636;
    color: white;
}
.logout-btn {
    position: fixed;
    left: 3px;
    bottom: 15px;
    width: 250px;
}
</style>