import axios from 'axios';

const state = {
}
const mutations = {
}
const actions = {
  migrateData({ commit }, params) {
    return axios.post(`${process.env.VUE_APP_API}/data/migrate`, params);
  },
  getEnvs({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/env/info?info_type=env`)
  },
  trackJob({ commit }, jobId) {
    return axios.get(`${process.env.VUE_APP_API}/job?job_id=${jobId}`)
  },
  getMigrationHistory({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/data/migrate/history`);
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}