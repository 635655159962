<template>
    <div>
        <v-dialog v-model="openTemp" fullscreen hide-overlay transition="dialog-bottom-transition">  
            <div class="full-view pa-4">
                <div class="full-view-header d-flex justify-space-between align-center">
                    <span class="text-h5">Prompt Template View</span>
                    <span style="cursor: pointer; z-index: 10;" @click="closePopup()" title="close"><v-icon>mdi-close</v-icon></span>
                </div>
                <div class="temp-info text-center mt-3">
                    <div class="d-flex pa-4 align-center justify-space-between" style="background-color: #e1e1e1 ; border-radius: 8px;">
                        <span style="font-size: 13px;" class="pa-0 ml-2"><b>Item Type</b>: {{ tempData.item_name }}</span>
                        <span class="pa-0 ml-2" style="font-size: 13px;"><b>Creator</b>:{{ tempData.created_by }}</span>
                        <span class="pa-0 ml-2" style="font-size: 13px;"><b>Version</b>: <v-chip color="primary" x-small>{{ tempData.version }}</v-chip></span>
                        
                        <span style="font-size: 13px;" class="pa-0 ml-2"><b>Created On</b>: {{ tempData.created_at | timeFilter }}</span>
                        <span style="font-size: 13px;" class="d-flex"> 
                            <b>Deployed to:</b> 
                            <span v-if="tempData.published">
                                <v-chip v-for="(en, i) in tempData.published_envs" :key="i" x-small :color="checkEnv(en)" class="ml-2">{{en}}</v-chip>
                                <!-- <v-chip rouneded color="orange darken-1" x-small>Develop</v-chip><v-chip rouneded color="pink lighten-4" x-small>QA</v-chip><v-chip rouneded color="indigo lighten-4" x-small>Staging</v-chip><v-chip rouneded color="deep-orange lighten-3" x-small>Pilot</v-chip><v-chip rouneded color="green lighten-2" x-small>Production</v-chip> -->
                            </span>
                            <span v-else><v-chip outlined x-small color="red" class="ml-2">Not Deployed</v-chip></span>
                        </span>
                    </div>
                </div>
                <v-tabs
                class="mt-3"
                    v-model="tab"
                    color="primary"
                    background-color="transparent"
                    grow
                >
                    <v-tab>
                        Prompt Instruction
                    </v-tab>
                    <v-tab>
                        Chain of thoughts
                    </v-tab>
                    <v-tab>
                        List of Technical Descriptions
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <div class="task-text ma-2 text-left pa-5 mt-5">
                            {{ promptData.task }}
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="d-flex align-center flex-column pa-4">
                            <span  class="font-weight-bold mb-2 mt-2" >Example Context</span>
                            <table border="1">
                                <thead>
                                    <tr>
                                        <th>Chunk id</th>
                                        <th>Chunk text</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(cc, i) in cot.example" :key="i">
                                        <td style="font-size: 12px;" class="pa-2">{{ cc.chunk_id }}</td>
                                        <td style="font-size: 12px;" class="pa-2">{{ cc.chunk_text }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span class="font-weight-bold mb-2 mt-2" >Example Input</span>
                            <table  border="1" width="100%">
                                <thead>
                                    <tr>
                                        <th>Tag ID</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(cc, i) in cot.input" :key="i">
                                        <td>{{ cc.tag_id }}</td>
                                        <td>{{ cc.tech_spec_name }}</td>
                                        <td>{{ cc.tech_spec_description }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span class="font-weight-bold mb-2 mt-2" >Example Output</span>
                            <div class="d-flex align-center flex-column bord" style="width: 100%;">
                                <div class="head d-flex align-center" style="width: inherit;">
                                    <div style="flex-basis: 33%;" class="bord">
                                        Name
                                    </div>
                                    <div style="flex-basis: 33%;" class="bord">
                                        Chunk ID
                                    </div>
                                    <div style="flex-basis: 33%;" class="bord">
                                        Chunk Value
                                    </div>
                                </div>
                                <div class="body d-flex align-center text-center bord" style="width: 99.6%;" v-for="(cc, i) in outKeys" :key="i">
                                    <div style="flex-basis: 33%; height: 100%; margin-right: 4px;">
                                        {{ cc }}
                                    </div>
                                    <div class="d-flex flex-column" style="flex-basis: 67%;">
                                        <div class="d-flex align-center" v-for="(co, i) in cot.output[cc]" :key="i">
                                            <div style="flex-basis: 50%; border-left: 1px solid black;" class="">{{ co.chunk_id }}</div>
                                            <div style="flex-basis: 50%; border-left: 1px solid black;" class="">{{ co.value }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div v-for="(prompt , i) in promptData.sub_items" :key="i">
                            <table v-for="(req, j) in  prompt.req_groups" :key="j" border="1" style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="pa-5">{{ req.req_group_name }}</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <table border="1" style="width: 100%;">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 205px;">Parameter</th>
                                                        <th style="width: 215px;">Retrieval Query</th>
                                                        <th style="width: 165px">Retrieval Score Cutoff</th>
                                                        <th style="width: 150px;">LLM Batch ID</th>
                                                        <th style="width: 205px;">LLM Technical Specification Name</th>
                                                        <th style="width: 565px">Technical Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(spec, k) in  req.tech_specs" :key="k">
                                                        <td>{{ spec.tech_spec_name }}</td>
                                                        <td>{{ spec.retrieval_query }}</td>
                                                        <td>{{ spec.relevant_score_cutoff }}</td>
                                                        <td>{{ spec.llm_batch_id }}</td>
                                                        <td>{{ spec.llm_tech_spec_name }}</td>
                                                        <td>{{ spec.llm_tech_spec_description }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios'

    export default {
        props: ['openTemp', 'tempData'],
        data() {
            return  {
                openView: false,
                promptData: {},
                cot: {},
                outKeys: [],
                tab: null
            }
        },
        filters: {
            timeFilter(val) {
                val = new Date(val)
                return `${val.getDate()}/${val.getMonth() + 1}/${val.getFullYear()} `
            }
        },
        // watch: {
        //     tempData(to, from) {
        //         console.log('Temp data => ', to, from);
        //     }
        // },
        created() {
            this.getPromptTempData()
        },
        methods: {
            closePopup() {
                this.$props.openTemp = false
                this.$emit('closePopup', false)
            },
            getPromptTempData() {
                axios({
                    url: process.env.VUE_APP_API + '/prompt/info?prompt_id=' + this.$props.tempData._id,
                    method: 'get'
                }).then((res) => {
                    this.promptData = res.data.data
                    this.cot.example = this.promptData.chain_of_thought.example_context
                    this.cot.input = this.promptData.chain_of_thought.example_input
                    this.cot.output = this.promptData.chain_of_thought.example_output
                    this.outKeys = Object.keys(this.cot.output)
                })
            },
            checkEnv(val) {
                if (val == 'Develop') {
                    return 'orange darken-1'
                }
                if (val == 'QA') {
                    return 'pink lighten-4'
                }
                if (val == 'Staging') {
                    return 'light-blue lighten-2'
                }
                if (val == 'Pilot') {
                    return  'deep-orange lighten-3'
                }
                if (val == 'Production') {
                    return 'green lighten-2'
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .full-view {
        background-color: white;
        height: 99.6vh;
        overflow-y: auto;
        .task-text {
            background-color: #ddd;
            border-radius: 16px;
        }
        .bord {
            border: 1px solid black;
            margin: 2px;
        }
    }
</style>