<template>
  <div class="home">
    <EnvDropDown/>
    <div class="d-flex mt-5">
      <v-spacer></v-spacer>
      <v-btn color="black" small class="elevation-0 white--text mr-3" rounded @click="createTemplate = true;" v-if="envChange == 'Admin'"> <v-icon>mdi-plus</v-icon> Create Template</v-btn>
    </div>
    <div class="d-flex align-center pr-3 mt-4">
      <span class="ml-6 text-h5">Prompt Template Listing</span>
      <v-spacer></v-spacer>
      <v-text-field
        label="Search Template"
        v-model="searchTemp"
        style="max-width: 450px;"
      ></v-text-field>
    </div>
    <v-data-table :headers="headers" :items="promptListing" :items-per-page="5" class="pa-2" :loading="loadData" :search="searchTemp">
      <template v-slot:item.item_name="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span style="font-size: 14px; cursor: pointer;" @click="openTemplateView(item)" v-bind="attrs" v-on="on">{{ item.item_name }}</span>
          </template>
          <span>View Template</span>
        </v-tooltip>
      </template>
      <template v-slot:item.version="{item}">
        <v-chip small color="primary">{{ item.version }}</v-chip>
      </template>
      <template v-slot:item.created_at="{item}">
        <span>{{ item.created_at | timeFilter }}</span>
      </template>
      <template v-slot:item.published_envs="{item}">
        <div class="d-flex flex-wrap" v-if="item.published">
          <v-chip v-for="(en, i) in item.published_envs" :key="i" small :class="'ma-1 env-chip ' + en" :color="checkEnv(en)">{{en}}</v-chip>
        </div>
        <div v-else>
          <v-chip outlined color="red" small>Not deployed</v-chip>
        </div>
      </template>
      <template v-slot:item.published_tenants="{item}" v-if="envChange != 'Admin'">
        <div class="d-flex flex-wrap">
          <v-chip v-for="(en, i) in item.published_tenants" :key="i" small class="ma-1 env-chip" color="blue lighten-4">{{en}}</v-chip>
        </div>
      </template>
      <template v-slot:item.created_by="{item}">
        <span style="font-size: 13px;">{{ item.created_by }}</span>
      </template>
      <template v-slot:item.action="{item}">

            <v-icon color="green darken-1" title="Download Template" style="cursor: pointer;" @click="downloadFile(item.template_file)">mdi-download-box</v-icon>
          
            <v-icon color="black" class="ml-3" title="Deploy Template" style="cursor: pointer;" @click.prevent="deployTemp = true;TempData = item;" v-if="envChange == 'Admin'">mdi-monitor-share</v-icon>
            <v-icon color="grey" class="ml-3" title="Deploy Template" style="cursor: not-allowed;;" v-else>mdi-monitor-share</v-icon>
        
            <v-icon color="red" class="ml-3" title="Delete Template" style="cursor: pointer;" v-if="!item.published && envChange == 'Admin'" @click.stop="deletePopup = true; deleteItem = item">mdi-delete</v-icon>
            <v-icon color="grey" class="ml-3" title="Delete Template" style="cursor: not-allowed;" v-else>mdi-delete</v-icon>
          
        <v-dialog v-model="deletePopup" persistent max-width="500px" transition="dialog-transition">
          <v-card>
            <v-card-title class="d-flex justify-space-between">
              <span>Delete Popup</span>
              <v-icon style="cursor: pointer;" @click="deletePopup = false;" title="close">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              Are you sure you want to delete this Prompt Template?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" small rounded class="elevation-0" @click.stop="deleteTemp()">Delete</v-btn>
              <v-btn color="red" small rounded class="white--text elevation-0" @click="deletePopup = false;">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>       
      </template>
    </v-data-table>
    <CreatePromptTemplate :createTemp="createTemplate" @closePopup="closeTempPopup" @uplloadedTemplate="getTempList"/>
    <DeployPopup :openDeploy="deployTemp" :tempData="TempData" @closePopup="closeTempPopup" @prompt-deployed="getTempList"/>
    <ViewPrompt v-if="seeView" :openTemp="seeView" :tempData="TempData" @closePopup="closeTempPopup"/>
    <noti-snac :msg="notiMsg" :snackText="noteText" v-if="loadNoti"></noti-snac>
  </div>
</template>

<script>
import CreatePromptTemplate from '@/components/PromptTemplate/promptTemplateCreate.vue'
import DeployPopup from '@/components/PromptTemplate/promptDeploy.vue'
import ViewPrompt from '@/components/PromptTemplate/PromptView.vue'
import EnvDropDown from '@/components/reusable/envDropdown.vue'
import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      headers: [
      { text: 'Item Type', value:'item_name', width: '250px'},  
      { text: 'Version', value: 'version', width: '100px' },
      { text: 'Creator', value:'created_by', width: '100px'},
      { text: 'Created On', value:'created_at' , width: '120px'},
      { text: 'Deployed Env', value:'published_envs', width: '300px'},
      { text: 'Actions', value:'action', align: 'center', width: '150px', sortable: false},
      ],
      promptListing: [],
      createTemplate: false,
      deployTemp: false,
      seeView: false,
      TempData: {},
      envChange: '',
      loadData: false,
      loadNoti: false,
      notiMsg: '',
      noteText: '',
      deletePopup: false,
      deleteItem: {},
      searchTemp: ''
    }
  },
  components: {
    CreatePromptTemplate,
    DeployPopup,
    ViewPrompt,
    EnvDropDown
  },
  filters: {
    timeFilter(val) {
      val = new Date(val)
      return `${val.getDate()}/${val.getMonth() + 1}/${val.getFullYear()} `
    }
  },
  mounted() {
    // console.log('axios =>', process.env.VUE_APP_API );
    window.addEventListener('env-localstorage-changed', (event) => {
      console.log(event);
      this.envChange = event.detail.storage;
      this.getTempList()
    });
    setTimeout(() => {
      this.getTempList()
    }, 10)
  },
  created() {
    this.envChange = localStorage.getItem('env')
  },
  methods: {
    deleteTemp() {
      axios({
        url: process.env.VUE_APP_API + '/prompt/delete?prompt_id=' + this.deleteItem._id,
        method: 'delete'
      }).then((res) => {
        console.log(res.data);
        this.deletePopup = false
        this.getTempList('delete-prompt')
      })
    },
    openTemplateView(val) {
      console.log(val);
      this.seeView = true;
      this.TempData = val;
      document.getElementsByTagName('html')[0].classList.add('overflow-y-hidden');
    },
    checkEnv(val) {
      console.log(val);
      if (val == 'Develop') {
        return 'orange darken-1'
      }
      if (val == 'QA') {
        return 'pink lighten-4'
      }
      if (val == 'Staging') {
        return 'light-blue lighten-3'
      }
      if (val == 'Pilot') {
        return  'deep-orange lighten-3'
      }
      if (val == 'Production') {
        return 'green lighten-2'
      }
    },
    closeTempPopup(val) {
      this.createTemplate = false
      this.deployTemp = false
      this.seeView = false
      document.getElementsByTagName('html')[0].classList.remove('overflow-y-hidden')
    },
    getTempList(val){
      console.log(val);
      this.promptListing = []
      this.loadData = true
      if (val == 'success') {
        this.notiMsg = 'success',
        this.noteText = 'Prompt Template Successfully Deployed'
        this.loadNoti = true
        setTimeout(() => {
          this.loadNoti = false
        }, 3000)
      }
      if (val == 'created-template') {
        this.notiMsg = 'success',
        this.noteText = 'Prompt Template created Successfully'
        this.loadNoti = true
        setTimeout(() => {
          this.loadNoti = false
        }, 3000)
      }
      if (val == 'delete-prompt') {
        this.notiMsg = 'success',
        this.noteText = 'Prompt Template Successfully Deleted'
        this.loadNoti = true
        setTimeout(() => {
          this.loadNoti = false
        }, 3000)
      }
      axios({
        url: process.env.VUE_APP_API + '/prompt/list?env=' + this.envChange,
        method: 'get'
      }).then((res) => {
        console.log(res.data);
        if (this.envChange != 'Admin') {
          this.headers[4].value = 'published_tenants'
        } else {
          this.headers[4].value = 'published_envs'
        }
        this.promptListing = res.data.data
        this.loadData = false
      })
    },
    downloadFile(val) {
      axios({
        url: process.env.VUE_APP_API + '/file/download/url?file=' + val,
        method: 'get'
      }).then((res) => {
          console.log(res.data.url);
          window.open(res.data.url, '_self')
      })
    }
  }
  
}
</script>
<style lang="scss" scoped>
  
</style>
