<template>
    <div class="migration-history-page">
      <div class="d-flex align-center pr-3 mt-4">
        <span class="ml-6 text-h6">Data Migration History</span>
        <v-spacer></v-spacer>
        <v-text-field label="Search items" v-model="searchItem" style="max-width: 450px;"></v-text-field>
      </div>
      <v-data-table :headers="headers" no-data-text="No record found" :items="historyListing" :items-per-page="10" class="pa-2" :loading="loadData"
            :search="searchItem">
        </v-data-table>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        searchItem: '',
        historyListing: [],
        loadData: false,
        headers: [
        // { text: 'Company', value:'company', width: '200px'},  
        { text: 'Source Environment', value: 'source_environment', width: '100px' },
        { text: 'Destination Environment', value: 'destination_environment', width: '80px' },
        { text: 'Remarks', value:'remarks', width: '100px'},
        { text: 'Status', value:'status' , width: '100px'},
        { text: 'Migrated At', value:'updated_at', width: '200px'},
        { text: 'Migrated By', value:'updated_by', width: '150px'},
        ],
      }
    },
    mounted() {
      this.loadData = true;
      this.$store.dispatch('migration/getMigrationHistory').then((resp) => {
        this.historyListing = resp.data.sections;
        this.loadData = false;
      })
    },
    methods: {
    
    }
}
</script>
<style lang="scss" scoped>
.form-container {
  max-width: 500px;
  margin-left: 25px;
  margin-top: 25px;
}
.left-align {
  display: block;
  //margin-right: auto;
  margin-left: auto;
  margin-top:20px;
}
</style>