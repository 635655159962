<template>
    <div>
        <v-dialog v-model="openDialog" width="600px" persistent>
            <v-card class="pa-5">
                <div class="d-flex justify-space-between">
                    <span class="text-h5">Deploy Template</span>
                    <span @click="closePopup()" style="cursor: pointer;" title="close"><v-icon>mdi-close</v-icon></span>
                </div>
                <div class="mt-6 py-3 pr-4 text-left" style="background-color: #ddd; border-radius: 8px;">
                    <div class="d-flex pl-4">
                        <span style="font-size: 13px; width: 49%;"><b>Item Type</b>: {{ tempData.item_name }}</span>
                        <span class="pa-0" style="font-size: 13px; width: 49%;"><b>Creator</b>:{{ tempData.created_by }}</span>
                    </div>
                    <div class="d-flex pl-4 text-left">
                        <span class="pa-0" style="font-size: 13px; width: 49%;"><b>Version</b>: <v-chip color="primary" x-small>{{ tempData.version }}</v-chip></span>
                        <span style="font-size: 13px; width: 49%;"><b>Created On</b>: {{ tempData.created_at | timeFilter }}</span>
                    </div>
                    <div class="d-flex pl-4 text-left">
                        <span class="pa-0 d-flex" style="font-size: 13px; width: 49%;"> 
                            <b>Deployed to:</b> 
                            <span v-if="tempData.published">
                                <v-chip class="ml-1" v-for="(en, i) in tempData.published_envs" :key="i" x-small :color="checkEnv(en)">{{en}}</v-chip>
                                <!-- <v-chip rouneded color="orange darken-1" x-small>Develop</v-chip><v-chip rouneded color="pink lighten-4" x-small>QA</v-chip><v-chip rouneded color="indigo lighten-4" x-small>Staging</v-chip><v-chip rouneded color="deep-orange lighten-3" x-small>Pilot</v-chip><v-chip rouneded color="green lighten-2" x-small>Production</v-chip> -->
                            </span>
                            <span v-else><v-chip outlined x-small color="red" class="ml-2">Not Deployed</v-chip></span>
                        </span>
                    </div>
                </div>
                <div class="d-flex mt-6">
                    <v-row>
                        <v-col md="12">
                            <v-select :items="envList" v-model="selectEnv" label="Select Env" outlined @change="selectedEnv" :menu-props="{ top: true, offsetY: true }"></v-select>
                        </v-col>
                        <!-- <v-col md="6"> -->
                            <!-- <v-select :items="selectedTelentList" v-model="selectTenent" item-text="name" item-value="_id" label="Select Tenent" outlined multiple :menu-props="{ top: true, offsetY: true }"></v-select> -->
                            <!-- <p>{{ selectedTelentList }}</p> -->
                        <!-- </v-col> -->
                    </v-row>                    
                </div>
                <v-card-actions class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn color="green" rounded class="elevation-0 white--text" height="35px" @click="deployTemp" :disabled="disableDeploy || loader">Deploy</v-btn>
                    <v-btn color="red" rounded class="elevation-0 white--text" height="35px" @click="closePopup()">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios'

    export default {
        props:['openDeploy', 'tempData'],
        data() {
            return {
                selectEnv:'Develop',
                envList:[],
                tenentList:{},
                loader: false
            }
        },
        computed: {
            selectedTelentList() {
                return this.tenentList[this.selectEnv]
            },
            disableDeploy() {
                if (this.selectEnv == '' ) {
                    return true
                } else {
                    return false
                }
            },
            openDialog() {
                if (this.$props.openDeploy) {
                    return true
                } else {
                    return false
                }
            }
        },
        filters: {
            timeFilter(val) {
                val = new Date(val)
                return `${val.getDate()}/${val.getMonth() + 1}/${val.getFullYear()} `
            }
        },
        created() {
            this.getEnv()
            this.getTenent()
        },
        methods: {
            getEnv() {
                axios({
                    url: process.env.VUE_APP_API + '/env/info?info_type=env',
                    method: 'get'
                }).then((res) => {
                    res.data.data.forEach((it, i) => {
                        if (it == 'Admin') {
                            res.data.data.splice(i, 1)
                        }
                    })
                    this.envList = res.data.data
                })
            },
            getTenent() {
                axios({
                    url: process.env.VUE_APP_API + '/env/info?info_type=tenant',
                    method: 'get'
                }).then((res) => {
                    this.tenentList = res.data.data
                })
            },
            closePopup(){
                // this.$props.openDeploy = false
                this.selectEnv ='Develop'
                // this.selectTenent = ''
                this.$emit('closePopup', false)
            },
            selectedEnv() {
                this.$forceUpdate()
            },
            checkEnv(val) {
                if (val == 'Develop') {
                    return 'orange darken-1'
                }
                if (val == 'QA') {
                    return 'pink lighten-4'
                }
                if (val == 'Staging') {
                    return 'light-blue lighten-2'
                }
                if (val == 'Pilot') {
                    return  'deep-orange lighten-3'
                }
                if (val == 'Production') {
                    return 'green lighten-2'
                }
            },
            deployTemp() {
                // let dataSet = {}
                // // dataSet[this.selectEnv] = this.selectTenent
                // if (this.envList.indexOf(this.selectEnv) >1) {
                //     for (let i = 1; i < this.envList.indexOf(this.selectEnv); i++) {
                //         dataSet[this.envList[i]] = []
                //         this.tenentList[this.envList[i]].forEach((val) => {
                //             dataSet[this.envList[i]].push(val._id)
                //         })
                //     }   
                // }
                this.loader = true;
                let envIndex = this.envList.lastIndexOf(this.selectEnv);
                let envs = this.envList.filter((item) => this.envList.lastIndexOf(item) <= envIndex);
                axios({
                    url: process.env.VUE_APP_API + '/prompt/publish',
                    method: 'post',
                    data: {
                        prompt_id: this.tempData._id,
                        to_envs: envs
                    }
                }).then((res) => {
                    this.loader= false;
                    this.$emit('prompt-deployed', 'success')
                    this.closePopup()
                })

            }
        }
    }
</script>
<style lang="scss" scoped>
</style>