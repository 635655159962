<template>
    <div>
        <v-snackbar :value="true" right :color="msg">
            {{ snackText }}
        </v-snackbar>
    </div>
</template>
<script>
    export default {
        props: ['msg', 'snackText'],
        data() {
            return {
                value: true
            }
        }
    }
</script>