<template>
    <div class="templateListing">
        <div class="d-flex align-center pr-3 mt-4">
            <span class="ml-6 text-h6">Templates</span>
            <v-spacer></v-spacer>
            <v-text-field label="Search items" v-model="searchItem" style="max-width: 450px;"></v-text-field>
          </div>
          <v-btn rounded class="elevation-0 white--text save-btn btn right-btn" height="35px" @click="editItem= {}; createTemplate = true">Add Template</v-btn>  
        <v-data-table :headers="headers" no-data-text="No record found" :items="templateListing" :items-per-page="10" class="pa-2" :loading="loadData"
            :search="searchItem">
            <template v-slot:item.is_active="{ item }">
                <span>{{item.is_active ? 'Active': 'Inactive'}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <span class="mdi mdi-pencil" @click="editTemplate(item)" title="Edit"></span>
                <!-- <span class="mdi mdi-eye-circle"></span> -->
                <span class="mdi mdi-delete" :disabled="true" color="red" title="Delete Template" style="cursor: pointer;"
                @click.stop="deletePopup = true; deleteItem = item"></span>
                <v-btn small rounded :class="item.is_active ? 'hidden': ''" class="elevation-0 btn save-btn" @click="publishTemplate(item)">publish</v-btn>
            </template>
        </v-data-table>
        <v-dialog v-model="deletePopup" persistent max-width="500px" transition="dialog-transition">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    <span>Delete Template</span>
                    <v-icon style="cursor: pointer;" @click="deletePopup = false; resetModal();" title="close">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="align-left">
                    Are you sure you want to delete this Template?
                </v-card-text>
                <v-card-actions class="btm-padding">
                    <v-spacer></v-spacer>
                    <v-btn color="success" small rounded class="elevation-0 save-btn btn" @click.stop="deleteTemplate(deleteItem)">Delete</v-btn>
                    <v-btn color="red" small rounded class="white--text elevation-0 cancel-btn btn"
                        @click="deletePopup = false; resetModal();">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createTemplate" class="" content-class="add-template" width="500px" persistent>
            <v-card width="500px" class="pa-5">
                <div class="d-flex justify-space-between">
                    <h4>{{editItem && editItem._id ? 'Edit Template' : 'Add Template'}}</h4>
                    <span @click="resetModal();" style="cursor: pointer;" title="close"><v-icon>mdi-close</v-icon></span>
                </div>
                <div class="">
                    <v-text-field v-model="templateName" hide-details="auto" label="Template name"></v-text-field>
                    <v-autocomplete
                        :items="itemList"
                        item-text="name"
                        item-value="_id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="selectedItem"
                        label="Select item"
                        :clearable="true"
                    ></v-autocomplete>
                    <label>Choose Document(s)</label>
                    <draggable :list="sectionList" :key="updateList">
                        <span v-for="(item, index) in sectionList" :key="index">
                            <v-checkbox :key="item.selected" v-model="item.selected" :label="item.name"></v-checkbox>
                        </span>
                    </draggable>
                </div>
                <v-card-actions class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn rounded class="elevation-0 white--text save-btn btn" height="35px" @click="createNewTemplate()">{{editItem && editItem._id ? 'Update' : 'Create'}}</v-btn>
                    <v-btn rounded class="elevation-0 white--text cancel-btn btn" height="35px" @click="resetModal();">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="#00bf55" height="70px" :timeout="2000" >
            <span class="mdi mdi-check-circle icon-16" color="#fff"></span>
            {{ snackbarText }}
        </v-snackbar>
        <v-snackbar v-model="snackbarWarning" color="#e56565" height="70px" :timeout="2000" >
            <span class="mdi mdi-close-circle icon-16" color="#fff"></span>
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>
  
<script>
  import draggable from 'vuedraggable'
  export default {
    data() {
      return {
        headers: [
        { text: 'Item', value:'item_name', width: '200px'},  
        { text: 'Template name', value: 'name', width: '200px' },
        { text: 'Status', value:'is_active', width: '85px'},
        { text: 'Last updated', value:'updated_at' , width: '150px'},
        { text: 'Updated by', value:'updated_by', width: '150px'},
        { text: 'Actions', value:'actions', align: 'center', width: '160px', sortable: false},
        ],
        searchItem: '',
        deleteItem: {},
        createTemplate: false,
        deletePopup: false,
        loadData: false,
        templateListing: [],
        templateName: '',
        itemList: [],
        snackbarText: '',
        snackbar: false,
        snackbarWarning: false,
        editItem: {},
        selectedItem: '',
        sectionList: [],
        updateList: false
      }
    },
    components: {
        draggable
    },
    mounted() {
        this.getTemplateList();
        this.$store.dispatch('items/getItemsList').then((resp) => {
            this.itemList = resp.data.items;
        })
        this.$store.dispatch('sections/getSectionList').then((resp) => {
            this.sectionList = resp.data.sections;
            this.sectionList.forEach((val) => {
                val['selected'] = false
            })
        })
    },
    created() {
      this.envChange = localStorage.getItem('env')
    },
    methods: {
        getTemplateList() {
            this.loadData = true;
            this.$store.dispatch('templates/getTemplateList').then((resp) => {
                this.templateListing = resp.data.templates;
                this.loadData = false;
            })
        },
        editTemplate(item) {
            this.createTemplate = true; 
            this.editItem = item;
            this.templateName = item.name;
            this.selectedItem = item.item_id;
            this.sectionList.forEach((val) => {
                if(item.section_id_order.includes(val._id)){
                    val['selected'] = true;
                }
            })
            // this.isRequired = item.line_item === 'Not Required' ? 'no' : 'yes'
        },
        createNewTemplate() {
            let selectedSections = this.sectionList.filter((item) => item.selected)
            let params = {
                    template_name: this.templateName,
                    item_id: this.selectedItem,
                    section_id_order: selectedSections.map(item => item._id)
                }
            if(this.editItem._id) {
                params['template_id'] = this.editItem._id;
                this.$store.dispatch('templates/updateTemplate', params).then(() => {
                    this.getTemplateList();
                    this.resetModal();
                    this.snackbarText = 'Updated Successfully';
                    this.snackbar = true;
                }).catch((err) => {
                    this.snackbarText = err.response.data.message;
                    this.snackbarWarning = true;
                    this.resetModal();
                });
            } else {
                this.$store.dispatch('templates/createTemplate', params).then(() => {
                    this.getTemplateList();
                    this.resetModal();
                    this.snackbarText = 'Created Successfully';
                    this.snackbar = true;
                }).catch((err) => {
                    this.snackbarText = err.response.data.message;
                    this.snackbarWarning = true;
                    this.resetModal();
                });
            }
        },
        publishTemplate(item) {
            let params = {
                template_id: item._id,
            }
            this.$store.dispatch('templates/publishTemplate', params).then(() => {
                this.getTemplateList();
                this.snackbarText = 'Published Successfully';
                this.snackbar = true;
            }).catch((err) => {
                this.snackbarText = err.response.data.message;
                this.snackbarWarning = true;
            });
        },
        
        deleteTemplate(item) {
            let params = {
                template_id: item._id,
                is_delete: true
            }
            this.$store.dispatch('templates/deleteTemplate', params).then(() => {
                this.getTemplateList();
                this.deleteItem= {};
                this.deletePopup = false;
                this.snackbarText = 'Deleted Successfully';
                this.snackbar = true;
            }).catch((err) => {
                this.snackbarText = err.response.data.message;
                this.snackbarWarning = true;
                this.deletePopup = false;
            });
        },
        resetModal() {
            this.createTemplate = false;
            this.templateName = '';
            this.selectedItem = '';
            this.sectionList.forEach((val) => {
                val['selected'] = false
            });
            this.updateList = !this.updateList;
        }
    }
  }
</script>
<style lang="scss" scoped>
.text-center {
    .mdi {
        font-size: 20px;
        padding: 0 5px;
        cursor: pointer;
    }
}
.radio-label {
    text-align: left;
    margin-top: 25px;
    margin-bottom: -10px;
}
.right-btn {
    margin-right: 10px;
    margin-left: auto;
    display: block;
    margin-bottom: 5px;
}
.align-left{
    text-align: left;
}
.btm-padding{
    padding-bottom: 15px !important;
}
.modal-btn {
    margin-bottom: 10px;
}
.basic-button {
    border: 1px solid #7f7b7b;
}
label {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    display: block;
}
.hidden {
    visibility: hidden;
}
</style>