<template>
    <div>
        <v-dialog v-model="createTemp" class="" width="500px" persistent>
            <v-card width="500px" class="pa-5">
                <div class="d-flex justify-space-between">
                    <span>Create Template</span>
                    <span @click="closePopup()" style="cursor: pointer;" title="close"><v-icon>mdi-close</v-icon></span>
                </div>
                <div class="">
                    <v-autocomplete
                        :items="itemList"
                        item-text="name"
                        item-value="_id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="selectedItem"
                        label="Select a item type"
                        :clearable="true"
                    ></v-autocomplete>
                    <v-file-input style="margin-left: -6px;" accept=".xlsx" ref="fileData" label="Drop or Click the zone to add a template file" v-model="files"></v-file-input>
                </div>
                <v-card-actions class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn color="black" rounded class="elevation-0 white--text" height="35px" :disabled="disableCreate" @click="CreatePrompt">Create</v-btn>
                    <v-btn color="red" rounded class="elevation-0 white--text" height="35px" @click="closePopup()">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';

    export default {
        props:['createTemp'],
        data() {
            return {
                dialog: true,
                files: null,
                itemList: [],
                selectedItem: ''
            }
        },
        computed: {
            disableCreate() {
                if (this.selectedItem == '' || this.files == null) {
                    return true
                } else {
                    return false
                }
            }
        },
        created() {
            this.getItemList()
        },
        methods: {
            closePopup() {
                this.$props.createTemp = false
                this.files = null
                this.selectedItem = ''
                this.$emit('closePopup', false)
            },
            getItemList() {
                axios({
                    url: process.env.VUE_APP_API + '/item/types?page=prompt_template',
                    method: 'get'
                }).then((res) => {
                    this.itemList = res.data.item_types
                })
            },
            CreatePrompt() {
                let filesData = new FormData()
                filesData.append('file', this.files)
                axios({
                    url: process.env.VUE_APP_API + '/file/upload?category=prompt',
                    method: 'post',
                    data: filesData
                }).then((res) => {
                    axios({
                        url: process.env.VUE_APP_API + '/extract/prompt',
                        method: 'post',
                        data: {
                            item_type_id: this.selectedItem,
                            file: res.data.file,
                            file_name: this.files.name
                        }
                    }).then((res) => {
                        this.files = {}
                        this.selectedItem = ''
                        this.$emit('uplloadedTemplate', 'created-template')
                        this.closePopup()
                    })
                })
            }
        }
    }
</script>