import AssociateItem from '@/views/items/associateItem.vue';
import axios from 'axios';

const state = {
}
const mutations = {
}
const actions = {
  getItemsList({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/item`);
  },
  getDisciplineList({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/discipline`);
  },
  getCategoryList({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/item_categories`);
  },
  createItem({ commit }, params) {
    return axios.post(`${process.env.VUE_APP_API}/item`, params);
  },
  updateItem({ commit }, params) {
    return axios.put(`${process.env.VUE_APP_API}/item`, params);
  },
  disableItem({ commit }, params) {
    return axios.delete(`${process.env.VUE_APP_API}/item`, {data:params});
  },
  deleteItem({ commit }, params) {
    return axios.delete(`${process.env.VUE_APP_API}/item`, {data:params});
  },
  getAssociateItemList({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/item/associate`)
  },
  associateItem ({ commit }, params) {
    return axios.post(`${process.env.VUE_APP_API}/item/associate`, params);
  },
  updateAssociatItem({ commit }, params) {
    return axios.put(`${process.env.VUE_APP_API}/item/associate`, params);
  },
  deleteAssociateItem({ commit }, params) {
    return axios.delete(`${process.env.VUE_APP_API}/item/associate`, {data:params});
  },
  uploadFile({ commit }, params) {
    return axios.post(`${process.env.VUE_APP_API}/file/upload?category=data_dictionary`, params)
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}