<template>
    <div class="itemsListing">
        <div class="d-flex align-center pr-3 mt-4">
            <span class="ml-6 text-h6">Rules</span>
            <v-spacer></v-spacer>
            <v-text-field label="Search items" v-model="searchItem" style="max-width: 450px;"></v-text-field>
          </div>
        <v-data-table :headers="headers" :items="promptListing" :items-per-page="5" class="pa-2" :loading="loadData"
            :search="searchItem">
            <template v-slot:item.actions="{ item }">
                <span class="mdi mdi-download" color="#1ca088" title="Download item" style="cursor: pointer;"
                @click="downloadFile(item.template_file)"></span>

                <span class="mdi mdi-pencil"></span>
                <span class="mdi mdi-delete" color="red" title="Delete Template" style="cursor: pointer;"
                @click.stop="deletePopup = true; deleteItem = item"></span>
            </template>
        </v-data-table>
        <v-dialog v-model="deletePopup" persistent max-width="500px" transition="dialog-transition">
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    <span>Delete Popup</span>
                    <v-icon style="cursor: pointer;" @click="deletePopup = false;" title="close">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    Are you sure you want to delete this Prompt Template?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" small rounded class="elevation-0" @click.stop="deleteTemp()">Delete</v-btn>
                    <v-btn color="red" small rounded class="white--text elevation-0"
                        @click="deletePopup = false;">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createItem" class="" width="500px" persistent>
            <v-card width="500px" class="pa-5">
                <div class="d-flex justify-space-between">
                    <span>Create Template</span>
                    <span @click="closePopup()" style="cursor: pointer;" title="close"><v-icon>mdi-close</v-icon></span>
                </div>
                <div class="">
                    <v-autocomplete
                        :items="itemList"
                        item-text="name"
                        item-value="_id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="selectedItem"
                        label="Select a item type"
                        :clearable="true"
                    ></v-autocomplete>
                    <v-file-input style="margin-left: -6px;" accept=".xlsx" ref="fileData" label="Drop or Click the zone to add a template file" v-model="files"></v-file-input>
                </div>
                <v-card-actions class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn color="black" rounded class="elevation-0 white--text" height="35px" :disabled="disableCreate" @click="CreatePrompt">Create</v-btn>
                    <v-btn color="red" rounded class="elevation-0 white--text" height="35px" @click="closePopup()">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
  export default {
    data() {
      return {
        headers: [
        { text: 'Item Type', value:'item_name', width: '250px'},  
        { text: 'Version', value: 'version', width: '100px' },
        { text: 'Creator', value:'created_by', width: '100px'},
        { text: 'Created On', value:'created_at' , width: '120px'},
        { text: 'Deployed Env', value:'published_envs', width: '300px'},
        { text: 'Actions', value:'actions', align: 'center', width: '150px', sortable: false},
        ],
        searchItem: '',
        deleteItem: {},
        createItem: false,
        deletePopup: false
      }
    },
    components: {
    },
    mounted() {
    },
    created() {
      this.envChange = localStorage.getItem('env')
    },
    methods: {
        downloadFile(val) {
            axios({
                url: process.env.VUE_APP_API + '/file/download/url?file=' + val,
                method: 'get'
            }).then((res) => {
                window.open(res.data.url, '_self')
            })
        }
    }
  }
  </script>
  <style lang="scss" scoped>
    
  </style>
  