import axios from 'axios';

const state = {
}
const mutations = {
}
const actions = {
  getSectionList({ commit }) {
    return axios.get(`${process.env.VUE_APP_API}/section`)
  },
  creteSection({ commit }, params) {
    return axios.post(`${process.env.VUE_APP_API}/section`, params)
  },
  updateSection({ commit }, params) {
    return axios.put(`${process.env.VUE_APP_API}/section`, params)
  },
  deleteSection({ commit }, params) {
    return axios.put(`${process.env.VUE_APP_API}/section`, params)
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}