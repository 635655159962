import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

const state= {
    Username: 'SuperAdmin',
    Password: 'kyP-apMJ~Q)@#}hG92AWj]b6E7;xXNw8"un!qeU*,t3$r?df('
  }
const getters= {
  }
const mutations= {
  }
const actions= {
    getProfileDetail({ commit }) {
      return axios.get(`${process.env.VUE_APP_API}/profile`)
    },
  }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}